<template>
  <div class="home-elght">
    <div class="detail">
      <div class="itemlist">
        <div class="item">
          <video
            id="video"
            class="videoplay"
            controls
            src="https://resources.wukongbc.com/video/waibu.mp4"
          ></video>
          <p>{{ langvalue.jiankong.one }}</p>
        </div>
        <div class="item">
          <video
            id="video"
            class="videoplay"
            controls
            src="https://resources.wukongbc.com/video/neibu.mp4"
          ></video>
          <p>{{ langvalue.jiankong.two }}</p>
        </div>
      </div>
      <h3>
        {{ langvalue.jiankong.three }}:<a href="https://ring.com"
          >https://ring.com</a
        >{{ langvalue.jiankong.five }}
        <br/>
        webmaster@wukongbc.com 

      </h3>
      <h3 style="margin-top: 30px;">
        {{ langvalue.jiankong.four }}:<a
          href="https://service.ezviz.com/download"
          >https://service.ezviz.com/download{{ langvalue.jiankong.six }}</a
        >
        <br/>
        webmaster@wukongbc.com 
      </h3>
    </div>
  </div>
</template>
<script>
import { ref, watch } from "vue";
import { useStore } from "vuex";
import { returnLanguage } from "@/assets/js/language";
export default {
  name: "AppHeaderNav",
  setup() {
    const store = useStore();
    // 拿到vuex中的分类列表
    const langtype = store.state.home.type;
    const type = ref(langtype);
    // eslint-disable-next-line no-undef
    const langvalue = ref(returnLanguage(langtype));

    const playvideo = () => {
      document.getElementById("video").play();
    };
    watch(
      () => store.state.home.type,
      (newVal) => {
        langvalue.value = returnLanguage(newVal);
        type.value = newVal;
      },
      { immediate: true }
    );
    return { langvalue, playvideo, type };
  },
};
</script>
<style scoped lang="less">
.videoplay {
  border-radius: 20px;
}
h3 {
  // 文字居左显示
  text-align: left;
  // 缩小行间距
  line-height: 1.5;
}
.home-elght {
  width: 100%;
  margin: 0px auto;
  text-align: center;
  padding: 70px;
  margin-bottom: 400px;
}
.title {
  margin: 0 auto;
  font-size: 28px;
  font-weight: bold;
  position: relative;
}
.title::before {
  content: "";
  position: absolute;
  left: calc(50% - 70px);
  bottom: -10px;
  width: 150px;
  height: 3px;
  background: linear-gradient(
    -90deg,
    rgba(217, 255, 253, 1),
    rgba(159, 177, 255, 1)
  );
}
.stitle {
  color: #ffffff50;
  font-weight: 500;
  font-size: 18px;
  margin-top: 30px;
}
.detail {
  width: 1240px;
  font-size: 20px;
  font-weight: 300;
  margin: 20px auto 50px;
  padding: 20px 0;
  // margin-bottom: 20px;
  .itemlist {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: calc(50% - 20px);
      margin: 10px;
      video {
        width: 100%;
      }
    }
  }
}
</style>
